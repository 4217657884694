import React, { ReactElement, useEffect, useState } from "react";
import styles from "./index.module.scss";
import Arrow from "./../../../../icons/icon_drop_down.svg";
import clsx from "clsx";

const OpenStop = (props): ReactElement => {
    const { next, type, screens, current, districts, setPlace, handleStop, direction } = props;

    const [open, setOpen] = useState<string>("");

    const handleOpen = (value) => {
        if (!open) {
            setOpen(value);
            setPlace(false);
        } else {
            if (value === open) {
                setOpen("");
                setPlace(true);
                // handleStop("");
            } else {
                setOpen(value);
                setPlace(false);
            }
        }
    };

    const changePlace = (StopID, BusID) => {
        if (type === "change") {
            if (screens !== 4) {
                next();
            }
            handleStop({ StopID: StopID, BusID: BusID });
            // if (current === value) {
            //     handleStop("");
            // } else {
            //     handleStop(value);
            // }
        }
    };

    return (
        <>
            {districts?.map((item) => (
                <div key={`${item.ID}-folder`}>
                    <button className={clsx(styles["item"], open === item.ID && styles["item_open"])} onClick={() => handleOpen(item.ID)} key={`${item.ID}-folder-btn`}>
                        <span>{item?.Name}</span>
                        <div className={clsx(styles["arrow"], open === item.ID ? styles["arrow_up"] : styles["arrow_down"])}>
                            <Arrow />
                        </div>
                    </button>
                    {item?.Stops?.map((itemStop) => (
                        <>
                            {open === item.ID ? (
                                <div className={styles.list}>
                                    <button
                                        className={clsx(styles["list_item"], current === itemStop?.ID && styles["list_item_current"])}
                                        onClick={() => changePlace(itemStop?.ID, itemStop?.BusID)}
                                    >
                                        <span className={styles.list_item_place}>{itemStop?.Name}</span>
                                        {direction !== "departure" ? <span className={styles.list_item_time}>{itemStop?.Time}</span> : null}
                                    </button>
                                </div>
                            ) : null}
                        </>
                    ))}
                </div>
            ))}
        </>
    );
};

export default OpenStop;
