import React, { ReactElement, useContext, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { NavigationContext } from "../../../template";
import Loader from "../../../components/Loader";
import Navigation from "../../../components/Navigation";
import Notification from "../../../components/Notification";
import CityList from "../components/CityList";
import TimeItem from "../components/TimeItem";
import OpenStop from "../components/OpenStop";
import { InputRadio } from "../../../components/Inputs/InputRadio";
import Button from "../../../components/Button";
import SlideScreen from "../../../components/SlideScreen";
import Month from "../components/Month";
import { IBusDataLocation, IBusDataPerson } from "../../../typec/IBus";

const BusRecord = (): ReactElement => {
    const [load, setLoad] = useState(true);
    const [screens, setScreen] = useState(0);
    const [data, setData] = useState<any>({
        type: "",
        city: [],
        arrival: { id: "", name: "", type: "", districts: [] },
        arrival_id: { StopID: "", BusID: "" },
        departure: { id: "", name: "", type: "", districts: [] },
        departure_id: { StopID: "", BusID: "" },
    });

    console.log(data);

    const [error, setError] = useState("");

    const createError = (str) => {
        setError(str);
    };
    const clearErrorInter = () => {
        setError("");
    };

    const context = useContext(NavigationContext);
    const navigate = useNavigate();

    const goToMain = () => {
        navigate("/");
        context.goToMain();
    };

    const prev = () => {
        if (screens == 0) {
            navigate("/");
        } else {
            setScreen(screens - 1);
            setError("");
        }
        if (screens === 1) {
            setIsVisibleCity(false);
            setData({
                ...data,
                type: "",
            });
            setContentVisible(false);
            setRadioButtonState("");
        }
    };

    const [jsonData, setJsonData] = useState<IBusDataLocation>();
    const [jsonDataPerson, setJsonDataPerson] = useState<IBusDataPerson>();

    const next = () => {
        if (screens !== 4) {
            setScreen(screens + 1);
        }
    };

    const saveData = () => {
        getDataPerson();
        setRadioButtonState("");
        setContentVisible(false);
        setScreen(0);
    };

    const [place, setPlace] = useState([]);

    const [radioButtonState, setRadioButtonState] = useState<string>("");

    const [isContentVisible, setContentVisible] = useState<boolean>(false);

    const handleTextClick = (name) => {
        if (!jsonDataPerson?.Exists) {
            setData({
                ...data,
                type: name,
            });
            setContentVisible(true);
            setRadioButtonState(name);
            setError("");
        }
    };

    useEffect(() => {
        setLoad(false);
        fetch(`/dev/vacation_transport_new/?all_in_one=""`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                setLoad(true);
                setJsonData(json);
            });
    }, []);

    useEffect(() => {
        getDataPerson();
    }, []);

    const getDataPerson = () => {
        setLoad(false);
        fetch(`/dev/vacation_transport_new`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                setLoad(true);
                setJsonDataPerson(json);
            });
    };

    useEffect(() => {
        if (screens === 1) {
            setData({
                ...data,
                arrival_id: {},
            });
        }
        if (screens === 3) {
            setData({
                ...data,
                departure_id: {},
            });
        }
    }, [screens]);

    const submitRecord = () => {
        setLoad(false);
        fetch("/dev/vacation_transport_new", {
            method: "POST",
            body: JSON.stringify({
                TimeArrivalID: data?.arrival_id?.BusID,
                StopArrivalID: data?.arrival_id?.StopID,
                TimeDepartureID: data?.departure_id?.BusID,
                StopDepartureID: data?.departure_id?.StopID,
                SelfTransport: data?.type === "yourself" ? true : false,
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    saveData();
                    clearErrorInter();
                    // setLoad(true);
                } else {
                    createError(json.Error);
                    setLoad(true);
                }
            });
    };

    const deleteRecord = () => {
        setLoad(false);
        fetch("/dev/vacation_transport_new", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.res) {
                    saveData();
                    clearErrorInter();
                    // setLoad(true);
                } else {
                    createError(json.Error);
                    setLoad(true);
                }
            });
    };

    const handleCity = (value) => {
        setData({
            ...data,
            city: value,
        });
    };

    const handleTimeArrival = (value) => {
        setData({
            ...data,
            arrival: value,
        });
    };

    const handleArrivalID = (value) => {
        setData({
            ...data,
            arrival_id: value,
        });
    };

    const handleTimeDeparture = (value) => {
        setData({
            ...data,
            departure: value,
        });
    };

    const handleDepartureID = (value) => {
        setData({
            ...data,
            departure_id: value,
        });
    };

    const arrivalText = data.arrival?.name?.replace("Прибытие", "Прибытие на работу");
    const departureText = data.departure?.name?.replace("Отправление", "Отправление с работы");

    const [isEditable, setIsEditable] = useState<boolean>(false);

    const [lastDayOfMonth, setLastDayOfMonth] = useState<number>();

    useEffect(() => {
        isEditableDate();
    }, [jsonDataPerson]);

    const isEditableDate = () => {
        const currentData = new Date();
        const currentYear = currentData.getUTCFullYear();
        const currentMonth = currentData.getMonth();

        const startDate = new Date(currentYear, currentMonth, 25);
        const endDate = new Date(currentYear, currentMonth + 1, 0);

        setLastDayOfMonth(endDate?.getDate());
        if (currentData >= startDate && currentData <= endDate) {
            setIsEditable(true);
        } else {
            setIsEditable(false);
        }
    };

    const [isVisibleCity, setIsVisibleCity] = useState<boolean>(false);

    const changeVisibleCity = () => {
        if (isEditable && !isVisibleCity) {
            setIsVisibleCity(!isVisibleCity);
            setData({
                ...data,
                type: "bus",
            });
            setContentVisible(true);
            setRadioButtonState("bus");
        }
    };

    return (
        <>
            {!load ? (
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                </>
            ) : (
                <>
                    <Navigation prev={prev} goToMain={goToMain}></Navigation>
                    <div className={styles.dis}>
                        <p className={styles.dis_title}>{screens === 0 ? "Расписание автобусов" : data.city?.Name}</p>
                        {screens == 0 && (
                            <>
                                {!isContentVisible && !jsonDataPerson?.Exists ? (
                                    <Notification type="info" icon="info">
                                        <span className={styles.bold}>Рациональность</span> – одна из пяти ценностей нашей компании. Мы ей следуем, поэтому проводим оптимизацию
                                        корпоративных маршрутов для удобства сотрудников.
                                    </Notification>
                                ) : null}
                                <p className={styles.title}>Как вы добираетесь до работы?</p>
                                {!jsonDataPerson?.Exists ? <p className={styles.description}>Поменять свой выбор вы сможете с 25 числа следующего месяца</p> : null}
                                <div className={styles.radio}>
                                    <div>
                                        <InputRadio
                                            checked={radioButtonState === "bus" || (jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport)}
                                            onClick={() => handleTextClick("bus")}
                                            secondTitle="Автобус"
                                            id_key="nesam"
                                            name="why"
                                            disabled={jsonDataPerson?.Exists && jsonDataPerson?.SelfTransport}
                                        />
                                    </div>
                                    <div>
                                        <InputRadio
                                            checked={radioButtonState === "yourself" || (jsonDataPerson?.Exists && jsonDataPerson?.SelfTransport)}
                                            onClick={() => handleTextClick("yourself")}
                                            secondTitle="Самостоятельно"
                                            id_key="sam"
                                            name="why"
                                            disabled={jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport}
                                        />
                                    </div>
                                </div>
                                <p className={styles.error}>{error}</p>
                                {isContentVisible && radioButtonState === "bus" ? (
                                    <>
                                        <Notification type="info" icon="info">
                                            Запишитесь на удобный маршрут на <span className={styles.bold}>{jsonDataPerson?.Month}</span>.{" "}
                                            {isEditable ? (
                                                <span>
                                                    Сделай выбор остановки до <span className={styles.bold}>{lastDayOfMonth}</span>{" "}
                                                    <Month type={"bold"} how={"prev"} monthName={jsonDataPerson?.Month} caseName={"genitive"} />.
                                                </span>
                                            ) : null}
                                        </Notification>
                                        <>
                                            <p className={styles.title}>Выберите город места работы</p>
                                            <div className={styles.list}>
                                                <CityList load={load} handleCity={handleCity} setLoad={setLoad} next={next} data={jsonData?.Cities}></CityList>
                                            </div>
                                        </>
                                    </>
                                ) : null}
                                {jsonDataPerson?.Exists && jsonDataPerson?.SelfTransport ? (
                                    <Notification type="info" icon="info">
                                        В <Month type={"bold"} how={"current"} monthName={jsonDataPerson?.Month} caseName={"prepositional"} /> вы добираетесь до работы
                                        самостоятельно. Изменить свой выбор на <Month how={"next"} monthName={jsonDataPerson?.Month} caseName={"nominative"} /> можно после 25{" "}
                                        <Month how={"current"} monthName={jsonDataPerson?.Month} caseName={"genitive"} />.
                                    </Notification>
                                ) : null}
                                {radioButtonState === "yourself" ? (
                                    <SlideScreen altitudeLevel="service">
                                        <Button type="save_not_margin" onClick={submitRecord}>
                                            Сохранить
                                        </Button>
                                    </SlideScreen>
                                ) : null}
                                {jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport && isEditable && !isVisibleCity ? (
                                    <Notification type="info" icon="info">
                                        Изменить свой выбор можно до {lastDayOfMonth} <Month how={"current"} caseName={"genitive"} />!
                                    </Notification>
                                ) : null}
                                {jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport && jsonDataPerson?.StopArrival === jsonDataPerson?.StopDeparture ? (
                                    <>
                                        <p className={styles.final}>
                                            На <span className={styles.bold}>{jsonDataPerson?.Month}</span> вы выбрали отстановку – <span>{jsonDataPerson?.StopArrival}</span>
                                        </p>
                                        <p className={styles.final_place}>
                                            Отправление на работу – <span>{jsonDataPerson?.StopArrivalTime}</span>
                                        </p>
                                        <p className={styles.final_place}>
                                            Выезд с работы – <span>{jsonDataPerson?.TimeDeparture}</span>
                                        </p>
                                    </>
                                ) : null}
                                {jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport && jsonDataPerson?.StopArrival !== jsonDataPerson?.StopDeparture ? (
                                    <>
                                        <p className={styles.final}>
                                            На <span className={styles.bold}>{jsonDataPerson?.Month}</span> вы выбрали маршруты:
                                        </p>
                                        <p className={styles.final_place}>
                                            <span>Отправление на работу – {jsonDataPerson?.StopArrivalTime}</span>
                                        </p>
                                        <span className={styles.final_place}>{jsonDataPerson?.StopArrival}</span>
                                        <p className={styles.final_place}>
                                            <span>Выезд с работы – {jsonDataPerson?.TimeDeparture}</span>
                                        </p>
                                        <span className={styles.final_place}>{jsonDataPerson?.StopDeparture}</span>
                                    </>
                                ) : null}
                                {context?.userdata.test_aval ? (
                                    <SlideScreen altitudeLevel="service">
                                        {jsonDataPerson?.Exists ? (
                                            <Button type="cancel_red" onClick={deleteRecord}>
                                                Удалить запись
                                            </Button>
                                        ) : null}
                                        {jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport && isEditable && !isVisibleCity ? (
                                            <Button type="save_not_margin" onClick={changeVisibleCity}>
                                                Изменить остановки
                                            </Button>
                                        ) : null}
                                    </SlideScreen>
                                ) : (
                                    <>
                                        {jsonDataPerson?.Exists && !jsonDataPerson?.SelfTransport && isEditable && !isVisibleCity ? (
                                            <SlideScreen altitudeLevel="service">
                                                <Button type="save_not_margin" onClick={changeVisibleCity}>
                                                    Изменить остановки
                                                </Button>
                                            </SlideScreen>
                                        ) : null}
                                    </>
                                )}
                            </>
                        )}
                        {screens == 1 && (
                            <>
                                <Notification type="info" icon="info">
                                    Выберите необходимое время прибытия на работу.
                                </Notification>
                                <div>
                                    {data?.city?.TimesArrival &&
                                        data?.city?.TimesArrival.map((item) => (
                                            <TimeItem next={next} handleTime={handleTimeArrival} type={"arrival"} item={item} key={item.ID}></TimeItem>
                                        ))}
                                </div>
                            </>
                        )}
                        {screens == 2 && (
                            <>
                                <Notification type="info" icon="info">
                                    Найдите ближайший к вам район и выберете удобную остановку.
                                </Notification>
                                <p className={styles.title}>{arrivalText}</p>
                                <div>
                                    <OpenStop
                                        next={next}
                                        districts={data.arrival?.districts}
                                        handleStop={handleArrivalID}
                                        setPlace={setPlace}
                                        type={"change"}
                                        direction={"arrival"}
                                        current={data.arrival_id?.StopID}
                                    />
                                </div>
                            </>
                        )}
                        {screens == 3 && (
                            <>
                                <div>
                                    <Notification type="info" icon="info">
                                        Выберите необходимое время отправления с работы.
                                    </Notification>

                                    <div>
                                        {data?.city?.TimesDeparture &&
                                            data?.city?.TimesDeparture.map((item) => (
                                                <TimeItem next={next} handleTime={handleTimeDeparture} type={"departure"} item={item} key={item.ID}></TimeItem>
                                            ))}
                                    </div>
                                </div>
                            </>
                        )}
                        {screens == 4 && (
                            <>
                                <Notification type="info" icon="info">
                                    Найдите ближайший к вам район и выберете удобную остановку.
                                </Notification>
                                <p className={styles.error}>{error}</p>
                                <p className={styles.title}>{departureText}</p>
                                <div>
                                    <OpenStop
                                        next={next}
                                        districts={data.departure?.districts}
                                        handleStop={handleDepartureID}
                                        setPlace={setPlace}
                                        type={"change"}
                                        direction={"departure"}
                                        current={data.departure_id?.StopID}
                                    />
                                </div>
                                {data.departure_id?.BusID ? (
                                    <SlideScreen altitudeLevel="service">
                                        <Button type="save_not_margin" onClick={submitRecord}>
                                            Cохранить
                                        </Button>
                                    </SlideScreen>
                                ) : null}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default BusRecord;
