import React, { ReactElement } from "react";
import CityItem from "../CityItem";

const CityList = (props): ReactElement => {
    return (
        <>
            {props.data?.map((list) => (
                <CityItem setData={props.handleCity} load={props.load} setLoad={props.setLoad} next={props.next} current={list} />
            ))}
        </>
    );
};

export default CityList;
